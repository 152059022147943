export default [
  {
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
      path: '',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
          {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                breadcrumb: [
                  {
                    text: "Dashboard",
                    active: true
                  }
                ],
                title: 'Dashboard',
                authRequired: true,
              }
          },
          {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{path:"/dashboard"}
                    },
                    {
                      text: "Account",
                      active: true
                    }
                ],
                title: 'Profile',
                authRequired: true,
              },
          },
          {
              path: '/earnings',
              name: 'earnings',
              component: () => import('@views/earnings/Earnings.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{path:"/dashboard"}
                    },
                    {
                      text: "Earnings",
                      active: true
                    }
                ],
                title: 'Earnings',
                authRequired: true,
              },
          },
          {
              path: '/orders',
              name: 'order',
              component: () => import('@views/orders/OrderList.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{path:"/dashboard"}
                    },
                    {
                      text: "Orders",
                      active: true
                    }
                ],
                title: "Orders List",
                authRequired: true,
              },
          },
          {
              path: '/orders/:orderId(\\d+)',
              name: 'order-view',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Orders",
                      to:{path:"/orders"}
                    },
                    {
                      text: "Show",
                      active: true
                    }
                ],
                title: "View Order",
                authRequired: true,
              },
          },
          {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notifications/NotificationList.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{path:"/dashboard"}
                    },
                    {
                      text: "Notifications List",
                      active: true
                    }
                ],
                title: "Notifications",
                authRequired: true,
              }
          },
          {
            path: '/chat',
            name: 'chat',
            component: () => import('@views/chat/Chats.vue'),
            meta: {
                breadcrumb: [
                  {
                    text: "Dashboard",
                    to:{path:"/"}
                  },
                  {
                    text: "Chats",
                    active: true
                  }
              ],
              title: "Chats",
              authRequired: true,
            }
          },
      ]
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
      path: '',
      component: () => import('@/layouts/FullPage.vue'),
      children: [
          {
              path: '/login',
              name: 'login',
              component: () => import('@/views/pages/Login.vue'),
              meta: {
                title: "Login",
                guestRequired: true,
              }
          },
          {
              path: '/register',
              name: 'register',
              component: () => import('@/views/pages/Register.vue'),
              meta: {
                title: "Register",
                guestRequired: true,
              }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta:{
                title: "404 - Error"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta:{
                title: "500 - Error"
              }
          },
      ]
  },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: '/error-404'
  }
]