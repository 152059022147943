
import axios from "@/plugins/axios.js"

const actions = {
    fetchTransactions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get("/transactions")
          .then((response) => {
            if(response.data.success){
                commit('SET_TRANSACTIONS', response.data.data.transactions)
              }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
}

const getters = {}

const mutations = {
    SET_TRANSACTIONS(state, transactions) {
      state.transactions = transactions
    },
    ADD_TRANSACTION(state, notification) {
        state.transactions.unshift(notification)
    },
}

const state = {
    transactions: [],
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

