
import axios from "@/plugins/axios.js"

const actions = {
  fetchOrders({ commit, state }, force = false) {
    if(!force && state.orders.length) return
    return new Promise((resolve, reject) => {
      axios.get("/orders")
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDERS', response.data.data.orders)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_ORDER', response.data.data.order)
            }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {}

const mutations = {
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_ORDER(state, order){
      state.order = order
    },
    UPDATE_ORDER(state, order){
      Object.assign(state.order, order)
      if(state.orders.length){
        const dataIndex = state.orders.findIndex((o) => o.id == order.id);
        Object.assign(state.orders[dataIndex], order);
      }
    },

    /**************** THIS IS FOR DELIVERIES********************/
    UPDATE_DELIVERY(state, delivery){
      if(state.order.delivery){
        Object.assign(state.order.delivery, delivery)
      }else{
        state.order.delivery = delivery
      }
    },
      /**************** THIS IS FOR MESSAGES********************/
    SET_MESSAGES(state, messages){
      state.order.delivery.messages = [...messages]
    },
    ADD_MESSAGE(state, message){
      state.order.delivery.messages.push(message)
    },
    UPDATE_MESSAGE(state, message){
      const dataIndex = state.order.delivery.messages.findIndex((a) => a.id == message.id);
      Object.assign(state.order.delivery.messages[dataIndex], message);
    },
    DELETE_MESSAGE(state, messageId){
      const itemIndex = state.order.delivery.messages.findIndex((item) => item.id == messageId)
        state.order.delivery.messages.splice(itemIndex, 1)
    },
}

const state = {
    orders: [],
    order: {},
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

