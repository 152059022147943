export default{
    methods:{
        absoluteUrl(path = ""){
            return this.$store.state.domain + path
        },
        calculateDeliveryTime(time){
            return time > 72
               ?  `${time/24} Days`
               : `${time} Hrs`
       },
        isImageFile(ext){
            return  ['png','jpeg','svg','jpg','webp'].includes(ext ? ext.toLowerCase() : null)
        },
        preText(content) {
            if(!content) return;
            return content.replace(/\n/g, '<br />')
        },
        alertSuccess(text){
            toastr.success(text, 'Success');
        },
        alertError(text){
            toastr.error(text, 'Something went worry', {timeOut: 3000});
        },
    }
}
